import { useMutation } from '@apollo/client';
import { Box, Typography, useTheme } from '@mui/material';
import { Sources } from 'quill';
import React, { useContext } from 'react';
import { UnprivilegedEditor, Value } from 'react-quill';
import { useDebouncedCallback } from 'use-debounce';
import { UpdateQuestionDocument } from '../../../../gql/mutations/__generated__/question.generated';
import { GeneratedQuestionsQuery } from '../../../../gql/queries/__generated__/question.generated';
import { QuestionTypeEnum } from '../../../../gql/types';
import { onError } from '../../../../utils/apollo/apolloHelper';
import Editor from '../../../shared/Editor';
import { AlertsContext } from '../../Alerts/context';
import { FreeResponseAnswerEdit } from './Answers/FreeResponseAnswerEdit';
import { MultipleChoiceAnswerEdit } from './Answers/MultipleChoiceAnswerEdit';
import { ShortAnswerAnswerEdit } from './Answers/ShortAnswerAnswerEdit';
import { GeneratedQuestionAdvancedSettings } from './GeneratedQuestionAdvancedSettings';
import { RequiredFieldAsterisk } from './RequiredFieldAsterisk';
import { StandardsEdit } from './StandardsEdit';
import { updateQuestionFields } from './questions';

type GeneratedQuestionEditProps = {
  question: GeneratedQuestionsQuery['generatedQuestions'][0];
};
export function GeneratedQuestionEdit({
  question,
}: GeneratedQuestionEditProps) {
  const [questionDelta, setQuestionDelta] = React.useState(
    question.richText.ops
  );
  const { dispatch } = useContext(AlertsContext);
  const [updateQuestion] = useMutation(UpdateQuestionDocument, {
    onError: onError(dispatch),
  });
  const theme = useTheme();
  // Debounce server update calls
  const debouncedServerUpdate = useDebouncedCallback(
    (editor: UnprivilegedEditor) => {
      const changes: Partial<
        GeneratedQuestionsQuery['generatedQuestions'][number]
      > = {
        richText: editor.getContents(),
        plainText: editor.getText(),
      };

      const questionInput = updateQuestionFields(question, changes);

      updateQuestion({ variables: { question: questionInput } });
    },
    2000 // delay in ms
  );
  const handleQuestionChange = (
    value: string,
    delta: Value,
    source: Sources,
    editor: UnprivilegedEditor
  ) => {
    if (source !== 'user') return;
    setQuestionDelta(editor.getContents());
    debouncedServerUpdate(editor);
  };

  const answer = () => {
    switch (question.questionType) {
      case QuestionTypeEnum.FreeResponse:
        return <FreeResponseAnswerEdit question={question} />;
      case QuestionTypeEnum.ShortAnswer:
        return <ShortAnswerAnswerEdit question={question} />;
      case QuestionTypeEnum.SelectOneMultipleChoice:
      case QuestionTypeEnum.SelectAllMultipleChoice:
        return <MultipleChoiceAnswerEdit question={question} />;
      default:
        break;
    }
  };

  return (
    <Box>
      <StandardsEdit question={question} />
      <Box sx={{ mb: 2.5, mt: 2.5 }}>
        <Typography sx={{ fontWeight: theme.typography.fontWeightBold, mb: 1 }}>
          Question
          <RequiredFieldAsterisk />
        </Typography>
        <Editor value={questionDelta} onChange={handleQuestionChange} />
      </Box>
      {answer()}
      <Box sx={{ mt: 2.5 }}>
        <GeneratedQuestionAdvancedSettings question={question} />
      </Box>
    </Box>
  );
}
