import { useMutation } from '@apollo/client';
import { AddOutlined, DeleteOutline } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  IconButton,
  Radio,
  Typography,
  useTheme,
} from '@mui/material';
import { cloneDeep } from 'lodash';
import { Sources } from 'quill';
import React, { useContext } from 'react';
import { UnprivilegedEditor, Value } from 'react-quill';
import { useDebouncedCallback } from 'use-debounce';
import { QuestionAttributesFragment } from '../../../../../gql/fragments/__generated__/question.generated';
import { UpdateQuestionDocument } from '../../../../../gql/mutations/__generated__/question.generated';
import { GeneratedQuestionsQuery } from '../../../../../gql/queries/__generated__/question.generated';
import {
  MultipleChoiceAnswerChoiceInput,
  QuestionTypeEnum,
} from '../../../../../gql/types';
import theme from '../../../../../theme';
import { onError } from '../../../../../utils/apollo/apolloHelper';
import InlineEditor from '../../../../shared/Editor/Inline';
import { AlertsContext } from '../../../Alerts/context';
import { RequiredFieldAsterisk } from '../RequiredFieldAsterisk';
import { DeepPartial, updateQuestionFields } from '../questions';

type MultipleChoiceAnswerEditProps = {
  question: GeneratedQuestionsQuery['generatedQuestions'][0];
};

export function MultipleChoiceAnswerEdit({
  question,
}: MultipleChoiceAnswerEditProps) {
  const { dispatch } = useContext(AlertsContext);
  const [multipleChoiceAnswerChoices, setMultipleChoiceAnswers] =
    React.useState<MultipleChoiceAnswerChoiceInput[]>(
      question.multipleChoiceAnswerChoices || []
    );
  const [updateQuestion] = useMutation(UpdateQuestionDocument, {
    onError: onError(dispatch),
  });
  const handleAddMultipleChoice = () => {
    const updatedMultipleChoiceAnswers = cloneDeep(multipleChoiceAnswerChoices);
    updatedMultipleChoiceAnswers?.push({
      text: '',
      richText: { ops: [] },
      isCorrect: false,
    });
    setMultipleChoiceAnswers(updatedMultipleChoiceAnswers);
  };
  const deleteMultipleChoice = (index: number) => {
    return () => {
      const updatedMultipleChoiceAnswers = cloneDeep(
        multipleChoiceAnswerChoices
      );
      updatedMultipleChoiceAnswers?.splice(index, 1);
      setMultipleChoiceAnswers(updatedMultipleChoiceAnswers);
    };
  };
  return (
    <Box>
      <Typography sx={{ fontWeight: theme.typography.fontWeightBold, mb: 1 }}>
        Answer Choices
        <RequiredFieldAsterisk />
      </Typography>
      {multipleChoiceAnswerChoices?.map((answer, i) => (
        <IndividualMultipleChoice
          question={question}
          setMultipleChoiceAnswers={setMultipleChoiceAnswers}
          deleteMultipleChoice={deleteMultipleChoice(i)}
          multipleChoice={answer}
          key={`gen-short-answer-${i}-${answer.id}`}
          selectOne={
            question.questionType === QuestionTypeEnum.SelectOneMultipleChoice
          }
        />
      ))}
      <Button startIcon={<AddOutlined />} onClick={handleAddMultipleChoice}>
        Add Answer
      </Button>
    </Box>
  );
}

type IndividualMultipleChoiceProps = {
  multipleChoice: NonNullable<MultipleChoiceAnswerChoiceInput>;
  question: QuestionAttributesFragment;
  deleteMultipleChoice: () => void;
  setMultipleChoiceAnswers: React.Dispatch<
    React.SetStateAction<MultipleChoiceAnswerChoiceInput[]>
  >;
  selectOne: boolean;
};

function IndividualMultipleChoice({
  question,
  multipleChoice,
  setMultipleChoiceAnswers,
  deleteMultipleChoice,
  selectOne,
}: IndividualMultipleChoiceProps) {
  const [multipleChoiceDelta, setMultipleChoiceDelta] = React.useState(
    multipleChoice.richText.ops
  );
  const { dispatch } = useContext(AlertsContext);
  const [updateQuestion] = useMutation(UpdateQuestionDocument, {
    onError: onError(dispatch),
  });
  const theme = useTheme();
  // Debounce server update calls
  const debouncedServerUpdate = useDebouncedCallback(
    (editor: UnprivilegedEditor) => {
      let index = question.multipleChoiceAnswerChoices?.findIndex(
        (answer) => answer.id === multipleChoice.id
      );
      if (!multipleChoice.id) {
        // this means the short answer is new and hasn't been saved to the db yet
        index = question.multipleChoiceAnswerChoices?.length;
      }

      if (index === undefined || index === -1) {
        return;
      }

      const updatedMultipleChoiceAnswers: MultipleChoiceAnswerChoiceInput[] =
        cloneDeep(question.multipleChoiceAnswerChoices) || [];

      if (updatedMultipleChoiceAnswers) {
        updatedMultipleChoiceAnswers[index] = {
          ...multipleChoice,
          id: multipleChoice.id,
          text: editor.getText(),
          richText: editor.getContents(),
        };
      }
      const changes: DeepPartial<QuestionAttributesFragment> = {
        multipleChoiceAnswerChoices:
          updatedMultipleChoiceAnswers as DeepPartial<
            QuestionAttributesFragment['multipleChoiceAnswerChoices']
          >,
      };

      const questionInput = updateQuestionFields(question, changes);
      updateQuestion({ variables: { question: questionInput } });
    },
    1000 // delay in ms
  );
  const handleAnswerChange = (
    value: string,
    delta: Value,
    source: Sources,
    editor: UnprivilegedEditor
  ) => {
    if (source !== 'user') return;
    setMultipleChoiceDelta(editor.getContents());
    debouncedServerUpdate(editor);
  };

  const handleDeleteMultipleChoice = () => {
    // delete it in local state first:
    deleteMultipleChoice();
    // then delete it in the db:
    const index = question.multipleChoiceAnswerChoices?.findIndex(
      (answer) => answer.id === multipleChoice.id
    );

    if (index === undefined || index === -1) {
      return;
    }

    const updatedMultipleChoiceAnswers = cloneDeep(
      question.multipleChoiceAnswerChoices
    );

    if (updatedMultipleChoiceAnswers) {
      updatedMultipleChoiceAnswers.splice(index, 1);
    }

    const changes: DeepPartial<QuestionAttributesFragment> = {
      multipleChoiceAnswerChoices: updatedMultipleChoiceAnswers,
    };

    const questionInput = updateQuestionFields(question, changes);
    updateQuestion({ variables: { question: questionInput } });
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1.5 }}>
      <Box width="100%">
        <InlineEditor
          value={multipleChoiceDelta}
          onChange={handleAnswerChange}
        />
      </Box>
      {selectOne ? (
        <Radio
          checked={multipleChoice.isCorrect}
          onChange={(e) => {
            const updatedMultipleChoiceAnswers = cloneDeep(
              question.multipleChoiceAnswerChoices
            );
            if (!updatedMultipleChoiceAnswers) {
              return;
            }
            const index = updatedMultipleChoiceAnswers.findIndex(
              (answer) => answer.id === multipleChoice.id
            );
            if (index !== -1) {
              updatedMultipleChoiceAnswers[index].isCorrect = e.target.checked;
              for (let i = 0; i < updatedMultipleChoiceAnswers.length; i++) {
                if (i !== index) {
                  updatedMultipleChoiceAnswers[i].isCorrect = false;
                }
              }
            }
            //   setMultipleChoiceAnswers(updatedMultipleChoiceAnswers);
            const changes: DeepPartial<QuestionAttributesFragment> = {
              multipleChoiceAnswerChoices: updatedMultipleChoiceAnswers,
            };

            const questionInput = updateQuestionFields(question, changes);
            updateQuestion({ variables: { question: questionInput } });
            setMultipleChoiceAnswers(updatedMultipleChoiceAnswers);
          }}
        />
      ) : (
        <Checkbox
          checked={multipleChoice.isCorrect}
          onChange={(e) => {
            const updatedMultipleChoiceAnswers = cloneDeep(
              question.multipleChoiceAnswerChoices
            );
            if (!updatedMultipleChoiceAnswers) {
              return;
            }
            const index = updatedMultipleChoiceAnswers.findIndex(
              (answer) => answer.id === multipleChoice.id
            );
            if (index !== -1) {
              updatedMultipleChoiceAnswers[index].isCorrect = e.target.checked;
            }
            //   setMultipleChoiceAnswers(updatedMultipleChoiceAnswers);
            const changes: DeepPartial<QuestionAttributesFragment> = {
              multipleChoiceAnswerChoices: updatedMultipleChoiceAnswers,
            };

            const questionInput = updateQuestionFields(question, changes);
            updateQuestion({ variables: { question: questionInput } });
            setMultipleChoiceAnswers(updatedMultipleChoiceAnswers);
          }}
        />
      )}
      <IconButton onClick={handleDeleteMultipleChoice}>
        <DeleteOutline />
      </IconButton>
    </Box>
  );
}
