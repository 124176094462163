import { useApolloClient, useLazyQuery, useQuery } from '@apollo/client';
import {
  Box,
  Button,
  Card,
  Divider,
  FormControl,
  Unstable_Grid2 as Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useEffect } from 'react';
import {
  GeneratedQuestionsDocument,
  GeneratedQuestionsQuery,
} from '../../../../gql/queries/__generated__/question.generated';
import { QuestionTypeEnum } from '../../../../gql/types';
import {
  useLocalStorage,
  useSessionStorage,
} from '../../../../utils/hooks/useStorage';
import {
  LS_PODSIE_AI_CREATE_FORM_INSTRUCTIONS,
  LS_PODSIE_AI_CREATE_FORM_LEVEL,
} from '../../../../utils/localStorageKeys';
import { questionTypeEnumToText } from '../../../../utils/options/questionTypeOptions';
import {
  SS_PODSIE_AI_CREATE_FORM_QUESTION_TYPE,
  SS_PODSIE_AI_CREATE_FORM_TEXT,
} from '../../../../utils/sessionStorageKeys';
import { AiCreateFormGradeLevel } from './AiCreateFormGradeLevel';
import { GeneratedQuestions } from './GeneratedQuestions';
import { QuestionsLoadingIndicator } from './QuestionsLoadingIndicator';

export const aiCreateFormWidth = 600;

export function AiCreateForm() {
  const theme = useTheme();
  const [gradeLevel, setGradeLevel] = useLocalStorage(
    LS_PODSIE_AI_CREATE_FORM_LEVEL,
    'Middle School'
  );
  const [numQuestions, setNumQuestions] = React.useState('3');
  const [questionType, setQuestionType] = useSessionStorage(
    SS_PODSIE_AI_CREATE_FORM_QUESTION_TYPE,
    QuestionTypeEnum.ShortAnswer
  );

  const [text, setText] = useSessionStorage(SS_PODSIE_AI_CREATE_FORM_TEXT, '');
  const [instructions, setInstructions] = useLocalStorage(
    LS_PODSIE_AI_CREATE_FORM_INSTRUCTIONS,
    ''
  );

  const [
    fetchGeneratedQuestions,
    { data: generatedQuestions, loading, variables: usedVariables },
  ] = useLazyQuery(GeneratedQuestionsDocument, {
    fetchPolicy: 'network-only',
  });
  const loaderRef = React.useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (loading && loaderRef.current) {
      loaderRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [loaderRef, loading]);
  const formVariables = () => ({
    levelName: gradeLevel,
    text,
    instructions,
    numQuestions: parseInt(numQuestions),
    questionType,
  });
  const { data } = useQuery(GeneratedQuestionsDocument, {
    variables: formVariables(),
    fetchPolicy: 'cache-only',
  });
  const handleInstructions = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInstructions(event.target.value);
  };

  const handleText = (event: React.ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value);
  };

  const handleGradeLevel = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGradeLevel(event.target.value);
  };

  const handleQuestionType = (event: SelectChangeEvent) => {
    setQuestionType(event.target.value as QuestionTypeEnum);
  };

  const handleNumQuestions = (event: SelectChangeEvent) => {
    setNumQuestions(event.target.value as string);
  };

  const clearText = () => {
    setText('');
    setInstructions('');
  };

  const apolloClient = useApolloClient();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await fetchGeneratedQuestions({
      variables: formVariables(),
    });
  };

  const discardQuestions = (questionIds: string[]) => {
    const data = apolloClient.readQuery({
      query: GeneratedQuestionsDocument,
      variables: usedVariables,
    });
    if (data) {
      // Modify the data as needed, e.g., setting generatedQuestions to an empty array
      apolloClient.writeQuery({
        query: GeneratedQuestionsDocument,
        variables: usedVariables,
        data: {
          ...data,
          generatedQuestions: data.generatedQuestions.filter(
            (question: GeneratedQuestionsQuery['generatedQuestions'][0]) =>
              !questionIds.includes(question.id)
          ),
        },
      });
    }
  };

  // only show short answer and free response for now:
  const filteredQuestionTypeEnums = [
    QuestionTypeEnum.ShortAnswer,
    QuestionTypeEnum.FreeResponse,
    QuestionTypeEnum.SelectOneMultipleChoice,
    QuestionTypeEnum.SelectAllMultipleChoice,
  ];

  const questions =
    generatedQuestions?.generatedQuestions || data?.generatedQuestions || [];

  const showGeneratedQuestions = questions.length > 0 && !loading;
  return (
    <>
      <Grid
        container
        sx={{ maxWidth: aiCreateFormWidth, width: '90%', margin: '40px auto' }}
      >
        <Grid xs={12}>
          <Typography
            variant="h3"
            sx={{ fontWeight: 400, mb: 1.5 }}
            color="primary"
          >
            Step 1 - Customize
          </Typography>
          <Card
            sx={{
              borderRadius: 2,
              backgroundColor: theme.palette.common.white,
              padding: 3,
            }}
          >
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2} sx={{ mb: 4 }}>
                <Grid xs={12} sm={6}>
                  <AiCreateFormGradeLevel
                    gradeLevel={gradeLevel}
                    handleGradeLevel={handleGradeLevel}
                  />
                </Grid>
                <Grid xs={12} sm={6}>
                  <FormControl fullWidth>
                    <Typography variant="h5" sx={{ mb: 0.5 }}>
                      Number of Questions:
                    </Typography>
                    <Select
                      id="num-questions-select"
                      value={numQuestions}
                      onChange={handleNumQuestions}
                    >
                      <MenuItem value="1">1</MenuItem>
                      <MenuItem value="3">3</MenuItem>
                      <MenuItem value="5">5</MenuItem>
                      <MenuItem value="10">10</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Box>
                <FormControl fullWidth>
                  <Typography variant="h5" sx={{ mb: 0.5 }}>
                    Question type:
                  </Typography>
                  <Select value={questionType} onChange={handleQuestionType}>
                    {filteredQuestionTypeEnums.map((questionType) => {
                      return (
                        <MenuItem
                          key={`${questionType}-key`}
                          value={questionType}
                        >
                          {questionTypeEnumToText(questionType)}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Box>
              <Box sx={{ mt: 3.5, mb: 3.5 }}>
                <FormControl fullWidth>
                  <Typography
                    id="question-content-text"
                    variant="h5"
                    sx={{ mb: 0.5 }}
                  >
                    Generate questions based on the following text:
                  </Typography>
                  <TextField
                    required
                    minRows={4}
                    maxRows={4}
                    value={text}
                    onChange={handleText}
                    id="question-content-text"
                    multiline
                    variant="outlined"
                    placeholder="Text to generate questions from"
                  />
                </FormControl>
              </Box>
              <Box sx={{ mt: 3.5, mb: 3.5 }}>
                <FormControl fullWidth>
                  <Typography
                    id="additional-instructions"
                    variant="h5"
                    sx={{ mb: 0.5 }}
                  >
                    Additional Instructions (optional)
                  </Typography>
                  <TextField
                    minRows={3}
                    maxRows={3}
                    value={instructions}
                    onChange={handleInstructions}
                    id="question-content-text"
                    multiline
                    placeholder="Any additional instructions for the AI to follow? For example, 'make the answer(s) very concise.'"
                    variant="outlined"
                  />
                </FormControl>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  variant="outlined"
                  sx={{
                    mr: 1,
                    borderColor: theme.palette.grays.dark,
                    backgroundColor: theme.palette.common.white,
                  }}
                  color="inherit"
                  onClick={clearText}
                >
                  Clear Text
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  disabled={loading}
                  type="submit"
                  sx={{ color: theme.palette.common.white }}
                >
                  Generate Questions
                </Button>
              </Box>
            </form>
          </Card>
        </Grid>
        <Box ref={loaderRef}>
          <QuestionsLoadingIndicator loading={loading} />
        </Box>
        {showGeneratedQuestions && (
          <>
            <Grid xs={12}>
              <Divider sx={{ mt: 5, mb: 5 }} />
            </Grid>
            <Grid xs={12}>
              <GeneratedQuestions
                generatedQuestions={questions}
                discardQuestions={discardQuestions}
              />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}
